<template>
  <div>
    <main class="main-content main-content--homepage-logged-out" id="main" role="main">
      <div class="mt-1"><slider></slider></div>
      <!-- Info block 1 -->
      <section class="info-section">
        <div class="container">
          <div class="head-box text-center">
            <h3 class="text-success mt-2">Chúng tôi là ai?</h3>
          </div>
          <div class="three-panel-block mt-2">
            <div class="row">
              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-block-overlay text-center mb-5 p-lg-3">
                  <CIcon :height="40" :content="cilApps"/> 
                  <h3 class="text-dark mt-2">Ứng dụng</h3>
                  <p class="px-4">Đội ngũ những kỹ sư phần mềm với nhiều năm kinh nghiệm, chuyên gia công & phát triển phần mềm cho những công ty hàng đầu Việt Nam & trên thế giới</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-block-overlay text-center mb-5 p-lg-3">
                  <CIcon :height="40" :content="cilMonitor"/> 
                  <h3 class="text-dark mt-2">Đa nền tảng</h3>
                  <p class="px-4">Hệ thống được thiết kế hoạt động đa nền tảng, có thể làm việc trên nhiều thiết bị khác nhau: máy tính để bàn, máy tính sách tay, điện thoại, tablet....</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-block-overlay text-center mb-5 p-lg-3">
                  <CIcon :height="40" :content="cilLightbulb"/> 
                  <h3 class="text-dark mt-2">Giải pháp</h3>
                  <p class="px-4">Áp dụng những công nghệ tối ưu, mạnh, linh hoạt, dể sử dụng & bảo trì để giải quyết vấn đề khách hàng, mang đến sự thoải mái & dể dàng nhất khi sử dụng</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-block-overlay text-center p-lg-3">
                  <CIcon :height="40" :content="cilCloudUpload"/> 
                  <h3 class="text-dark mt-2">Lưu trữ đám mây</h3>
                  <p class="px-4">Chuyên thiết kế & triển khai hệ thống ứng dụng lưu trữ trực tuyến website, mobile, an toàn, bảo mật tuyệt đối, truy cập mọi lúc mọi nơi</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-block-overlay text-center p-lg-3">
                  <CIcon :height="40" :content="cilDiamond"/> 
                  <h3 class="text-dark mt-2">Chất lượng</h3>
                  <p class="px-4">Với tâm huyết tạo ra sản phẩm & dịch vụ tốt nhất cho khách hàng, Do đó Chất lượng sản phẩm là yếu tố được quan tâm hàng đầu</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-block-overlay text-center p-lg-3">
                  <CIcon :height="40" :content="cilChatBubble"/> 
                  <h3 class="text-dark mt-2">Hổ trợ 24/7</h3>
                  <p class="px-4">Đội ngũ chuyên viên tư vấn chuyên nghiệp sẵn sàng hổ trợ khách hàng mọi lúc mọi nơi</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Info block 1 -->
      <!-- <section class="info-section py-0 mt-5 mb-5">
        <div class="container">
          <div class="row">
            <div class="col content-half">
              <div class="head-box text-center col-md-12 mb-5">
                <h3 class="text-success">CÔNG NGHỆ</h3>
              </div>
              <div class="row mt-5">
                <div></div>
                <div class="col-lg-2 col-md-4 col-sm-4">
                  <img src="https://www.python.org/static/apple-touch-icon-144x144-precomposed.png"> 
                </div>
                <div class="col-lg-2 col-md-4 col-sm-4">
                  <img src="https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/programming-language%2F.net.png?alt=media&token=29774751-a494-4b8d-916d-277878099058"> 
                </div>
                <div class="col-lg-2 col-md-4 col-sm-4">
                  <img src="https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/programming-language%2Flaravel.png?alt=media&token=46fe8725-ef2c-4d9e-bb8a-ae915a4ad9b3"> 
                </div>
                <div class="col-lg-2 col-md-4 col-sm-4">
                  <img src="https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/programming-language%2Fnodejs.png?alt=media&token=5a129797-e62e-42b7-b9ea-2171f35544a3"> 
                </div>
                <div class="col-lg-2 col-md-4 col-sm-4">
                  <img src="https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/programming-language%2Freact-native.png?alt=media&token=ba55d047-fb40-459d-aa18-d29a84dd41fc"> 
                </div>
                <div class="col-lg-2 col-md-4 col-sm-4">
                  <img src="https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/programming-language%2Fvue.jpg?alt=media&token=8f192ee5-6efd-4881-a997-c932d018d32c"> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> -->
      <!-- Info block 1 -->
      <section class="info-section py-0 mt-5">
        <div class="container">
          <div class="row">
            <div class="col content-half">
              <div class="head-box text-center col-md-12">
                <h3 class="text-success">SẢN PHẨM CHÍNH</h3>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                  <span class="list-content ml-2 mt-2 text-dark">
                    <a href="/product/cafe" class="text-dark"><CIcon :height="20" :content="cilStar"/> QUẢN LÝ CHUỖI QUÁN CAFE</a>
                  </span>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                  <span class="list-content ml-2 mt-2 text-dark">
                    <a href="/product/vlxd" class="text-dark"><CIcon :height="20" :content="cilStar"/> QUẢN LÝ CHUỖI CỬA HÀNG VẬT LIỆU XÂY DỰNG</a>
                  </span>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                  <span class="list-content ml-2 mt-2 text-dark">
                    <a href="/product/dentist" class="text-dark"><CIcon :height="20" :content="cilStar"/> QUẢN LÝ CHUỖI PHÒNG KHÁM NHA KHOA</a>
                  </span>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                  <span class="list-content ml-2 mt-2 text-dark">
                    <a href="/product/pharmacy" class="text-dark"><CIcon :height="20" :content="cilStar"/> QUẢN LÝ CHUỖI QUẦY BÁN THUỐC TÂY</a>
                  </span>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                  <span class="list-content ml-2 mt-2 text-dark">
                    <a href="/product/karaoke" class="text-dark"><CIcon :height="20" :content="cilStar"/> QUẢN LÝ CHUỖI PHÒNG KARAOKE</a>
                  </span>
                </div>
                
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                  <span class="list-content ml-2 mt-2 text-dark">
                    <a href="/product/food" class="text-dark"><CIcon :height="20" :content="cilStar"/> QUẢN LÝ CHUỖI NHÀ HÀNG & QUÁN ĂN</a>
                  </span>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                  <span class="list-content ml-2 mt-2 text-dark">
                    <a href="/product/pawn" class="text-dark"><CIcon :height="20" :content="cilStar"/> QUẢN LÝ CHUỖI CỦA HÀNG CẦM ĐỒ</a>
                  </span>
                </div>
                
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                  <span class="list-content ml-2 mt-2 text-dark">
                    <a href="/product/football" class="text-dark"><CIcon :height="20" :content="cilStar"/> QUẢN LÝ CHUỖI SÂN TẬP THỂ THAO</a>
                  </span>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                  <span class="list-content ml-2 mt-2 text-dark">
                    <a href="/product/tole" class="text-dark"><CIcon :height="20" :content="cilStar"/> QUẢN LÝ CHUỖI CƠ SỞ KINH DOANH TOLE-SẮT-THÉP</a>
                  </span>
                </div>
                
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                  <span class="list-content ml-2 mt-2 text-dark">
                    <a href="/product/hotel" class="text-dark"><CIcon :height="20" :content="cilStar"/> QUẢN LÝ CHUỖI NHÀ NGHỈ & KHÁCH SẠN</a>
                  </span>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                  <span class="list-content ml-2 mt-2 text-dark">
                    <a href="/product/hostel" class="text-dark"><CIcon :height="20" :content="cilStar"/> QUẢN LÝ CHUỖI CHO THUÊ NHÀ TRỌ</a>
                  </span>
                </div>
                
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                  <span class="list-content ml-2 mt-2 text-dark">
                    <a href="/product/water" class="text-dark"><CIcon :height="20" :content="cilStar"/> QUẢN LÝ CHUỖI KINH DOANH BIA & NƯỚC GIẢI KHÁT</a>
                  </span>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                  <span class="list-content ml-2 mt-2 text-dark">
                    <a href="/product/bida" class="text-dark"><CIcon :height="20" :content="cilStar"/> QUẢN LÝ CHUỖI BÀN BIDA</a>
                  </span>
                </div>
                
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                  <span class="list-content ml-2 mt-2 text-dark">
                    <a href="/product/spa" class="text-dark"><CIcon :height="20" :content="cilStar"/> QUẢN LÝ CHUỖI CƠ SỞ SPA</a>
                  </span>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4 mb-2">
                  <span class="list-content ml-2 mt-2 text-dark">
                    <a href="/product/oto" class="text-dark"><CIcon :height="20" :content="cilStar"/> QUẢN LÝ CHUỖI CƠ SỞ SỬA CHỮA OTO & XE MÁY</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script type='text/javascript' src='https://maps-generator.com/google-maps-authorization/script.js?id=d92bb4403718cdf4089e05de783e9e9dc71a2f41'></script>
<script>
import Slider from './Slider'
import Product from '../product/Product'
import { cilStar, cilCloudUpload, cilApps,cilMonitor,cilDiamond,cilChatBubble,cilLightbulb} from '@coreui/icons'

export default {
  name: 'Home',
  components: {Slider,Product},
  data: () => {
    return {
      cilStar,cilCloudUpload,cilApps,cilMonitor,cilDiamond,cilChatBubble,cilLightbulb
    }
  },
  methods: {
    primaryNavigationToggle(){
      $(this).toggleClass('open');
      $(this).find('.fa').toggleClass('fa-bars fa-close');
      $(".off-canvas-wrap").toggleClass("move-right");
      $(".off-canvas-content").toggleClass("overflowa");
    },
    offCanvasExit(){
      $('.primary-navigation-toggle').removeAttr('style');
      $('.primary-navigation-toggle .fa').removeClass('fa-close');
      $('.primary-navigation-toggle .fa').addClass('fa-bars');
      $(".off-canvas-content").addClass("overflowa");
      $(".off-canvas-wrap").removeClass("move-right move-left");
      $('.off-canvas-sidebar-close').hide();
    },
    offCanvasSidebarClose(){
      $this = $(this);
      $(".off-canvas-content").addClass("overflowa");
      $(".off-canvas-wrap").toggleClass("move-left");
      $this.hide();
    },
    offCanvasSidebarToggle(){
      $(".off-canvas-wrap").toggleClass("move-left");
      $('.off-canvas-sidebar-close').show();
      $(".off-canvas-content").removeClass("overflowa");
    }
  }
}
</script>
